<template>
<div>
    <v-card flat style="background-color: var(--v-background-base) !important">
        <v-card class="mx-auto" :max-width="!$vuetify.breakpoint.mobile ? '100%' : '100%'">
            <v-card-text class="pa-0" :style="{
                        height: !$vuetify.breakpoint.mobile ? 'auto' : 'auto',
                        'overflow-y': 'auto',
                    }">
                <v-card :class="!$vuetify.breakpoint.mobile ? 'tempView' : 'tempView_mobile'" flat style="contain: content">
                    <div v-if="!$vuetify.breakpoint.mobile">
                        <v-img style="z-index: 0; width: 80%; aspect-ratio: 16/9; position: absolute; right: 0" :src="latestArticle.mediumImage"></v-img>
                        <div style="z-index: 3; width: 80%; aspect-ratio: 16/9; position: absolute; right: 0; background-image: radial-gradient(ellipse at right top, #46464671 -10%, #212121 56%, #212121 100%);"></div>
                    </div>
                    <div v-if="$vuetify.breakpoint.mobile">
                        <v-img style="z-index: 0; width: 100%; aspect-ratio: 1/1; position: absolute; right: 0" :src="latestArticle.mediumImage"></v-img>
                        <div style="z-index: 3; width: 100%; aspect-ratio: 1/1; position: absolute; right: 0; background-image: radial-gradient(ellipse at right top, #46464671 -10%, #212121 56%, #212121 100%);"></div>
                    </div>
                    <div v-if="!$vuetify.breakpoint.mobile" style="z-index: 10;position: absolute; top: 40%; left: 10%; width: 40%; line-height: 1.2; font-size: large">
                        <h3 class="pb-2" style="color: var(--v-redPop-base) !important"><i>Latest Article</i></h3>
                        <h1>{{ latestArticle.Title }}</h1>
                        <v-btn class="mt-5" rounded style="background-color: rgba(239, 239, 239, 0.885); color: rgb(27, 26, 26)" @click="readArticle(latestArticle)">Read Article</v-btn>
                    </div>
                    <div v-if="$vuetify.breakpoint.mobile" style="z-index: 10;position: absolute; top: 20%; left: 5%; width: 90%; line-height: 1.2; font-size: large">
                        <h3 class="pb-2" style="color: var(--v-redPop-base) !important"><i>Latest Article</i></h3>
                        <h1>{{ latestArticle.Title }}</h1>
                        <v-btn class="mt-5" rounded style="background-color: rgba(239, 239, 239, 0.885); color: rgb(27, 26, 26)" @click="readArticle(latestArticle)">Read Article</v-btn>
                    </div>
                </v-card>
                <v-card :class="!$vuetify.breakpoint.mobile ? 'tempView2' : 'tempView2_mobile' " flat>
                    <v-toolbar dense flat style="contain: content;">
                        <v-toolbar-title>Categories</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-btn icon v-if="!$vuetify.breakpoint.mobile">
                            <v-icon>filter_alt</v-icon>
                        </v-btn>
                        <v-text-field v-if="!$vuetify.breakpoint.mobile" prepend-inner-icon="search" label="Search Articles" style="max-width: 15vw; border-radius: 16px" dense outlined hide-details></v-text-field>
                    </v-toolbar>
                    <v-card-text class="pt-0 px-0" v-if="!$vuetify.breakpoint.mobile">
                        <div v-for="(category, idx) in categorisedArticles" :key="idx" style="margin-bottom: 1.5vh">
                            <v-subheader style="height: 1vh">
                                <span class="subheaderTitle">{{ category.category}}
                                    <v-btn plain class="exploreAll pb-0" small :ripple="false">Explore All <v-icon left small>chevron_right</v-icon>
                                    </v-btn>
                                </span>
                            </v-subheader>
                            <div class="carousel py-2">
                                <v-btn class="handle" @click="moveSlider(`slide${idx}`, 'left', category)" :disabled="isFirstArticleInView(category)">
                                    <v-icon class="left-handle">chevron_left</v-icon>
                                </v-btn>
                                <div class="slider" :id="`slide${idx}`">
                                    <v-card @click="readArticle(article)" elevation="10" v-for="(article, index) in category.articles" :key="index" :id="`${category.category}-${index}`" class="articleCard" :style="{'background-image': `url(${article.smallImage}) !important` }">
                                        <div class="textWrapper">
                                            <div class="textBottom">
                                                <v-card-title class="articleTitle">{{article.articleName}}</v-card-title>
                                                <v-card-subtitle class="showOnHover">
                                                    <v-icon small class="mr-1">account_circle</v-icon>{{article.author}}
                                                    <br>
                                                    <v-icon>schedule</v-icon>{{ formatPublicationDate(article.createdAt) }}
                                                </v-card-subtitle>
                                                <div class="showOnHover d-flex justify-center " style="position: absolute; bottom: 0; width: 100%">
                                                    <v-btn plain @click="readArticle(article)">
                                                        <v-icon class="mr-1" size="20">read_more</v-icon> Read Article
                                                    </v-btn>
                                                </div>
                                            </div>
                                        </div>
                                    </v-card>
                                </div>
                                <v-btn class="handle " @click="moveSlider(`slide${idx}`, 'right', category)" :disabled="isLastArticleInView(category)">
                                    <v-icon class="right-handle">chevron_right</v-icon>
                                </v-btn>
                            </div>
                        </div>
                    </v-card-text>
                    <v-card-text class="pl-2" v-if="$vuetify.breakpoint.mobile">
                        <div v-for="(category, idx) in categorisedArticles" :key="idx" style="margin-bottom: 1vh;">
                            <v-subheader style="height: 1vh">
                                <span class="subheaderTitle">{{ category.category}}
                                    <v-btn plain class="exploreAll pb-0" small :ripple="false">Explore All <v-icon left small>chevron_right</v-icon>
                                    </v-btn>
                                </span>
                            </v-subheader>
                            <div>
                                <v-card @click="readArticle(article)" elevation="10" v-for="(article, index) in category.articles" :key="index" :id="`${category.category}-${index}`" class="articleCard_mobile my-4" :style="{'background-image': `url(${article.smallImage}) !important` }">
                                    <div class="textWrapper">
                                        <div class="textBottom_mobile">
                                            <v-card-title class="articleTitle">{{article.articleName}}</v-card-title>
                                            <v-card-subtitle>
                                                <v-icon small class="mr-1">account_circle</v-icon>{{article.author}}
                                                <br>
                                                <v-icon>schedule</v-icon>{{ formatPublicationDate(article.createdAt) }}
                                            </v-card-subtitle>
                                            <div class="d-flex justify-center " style="position: absolute; bottom: 0; width: 100%">
                                                <v-btn plain @click="readArticle(article)">
                                                    <v-icon class="mr-1" size="20">read_more</v-icon> Read Article
                                                </v-btn>
                                            </div>
                                        </div>
                                    </div>
                                </v-card>
                            </div>
                        </div>
                    </v-card-text>
                </v-card>
            </v-card-text>
        </v-card>
    </v-card>
    <v-dialog v-if="!$vuetify.breakpoint.mobile" v-model="articleDialog" width="40vw" overlay-opacity="0.9">
        <v-card height="auto" class="pa-0">
            <div style="height: 30vh; width: 100%" :style="selectedArticle.mediumImage ? {'background-image':`url(${selectedArticle.mediumImage} !important`, 'background-size': 'cover'} : ''">
                <div style="height: 100%; width: 100%;background-image: linear-gradient(to bottom, #3d3d3d57, #3d3d3d); ">
                    <div style="position: absolute; width: 70%; top: 5vh; left: 3vw">
                        <h1 v-if="selectedArticle.Title">{{ selectedArticle.Title }}</h1>
                        <span>
                            <v-icon>account_circle</v-icon>{{ selectedArticle.Author ?? '' }}
                        </span>
                        <br>
                        <span>
                            <v-icon>schedule</v-icon>{{ formatPublicationDate(selectedArticle.createdAt) }}
                        </span>
                    </div>
                </div>
            </div>
            <div class="px-7">
                <v-card-text v-if="selectedArticle.Body" style="white-space: pre-line" v-html="markdownToHtml(selectedArticle.Body)">
                </v-card-text>
                <div class="px-1">
                    Tags:
                    <v-chip class="ma-1" :key="index" color="primary" outlined v-for="(item, index) in selectedArticle.Tags">
                        {{ item }}
                    </v-chip>

                    <v-chip class="ma-1" :key="index" color="primary" outlined v-for="(item, index) in selectedArticle.Regions">
                        {{ item }}
                    </v-chip>
                </div>
                <div class="px-1">
                    <v-list>
                        <v-list-group v-if="selectedArticle.Citations" no-action style="padding-left: 0;">
                            <template v-slot:activator>
                                <v-list-item-content>
                                    <v-list-item-title class="headline">Sources</v-list-item-title>
                                </v-list-item-content>
                            </template>

                            <!-- <v-list-item-group style="padding-left: 0;"> -->
                                <v-list-item v-for="(citation, index) in selectedArticle.Citations" :key="index">
                                    <v-list-item-content>
                                        <v-list-item-title style="font-size: 12px; white-space: normal !important;">{{ index + 1 }}. {{ citation.Title }}.<br> {{ citation["Source URL"] }}: ({{ citation.Source }})</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            <!-- </v-list-item-group> -->
                        </v-list-group>

                        <v-alert v-else :value="true" type="info">No Sources available</v-alert>
                    </v-list>
                </div>
            </div>
        </v-card>
    </v-dialog>
    <v-dialog v-if="$vuetify.breakpoint.mobile" v-model="articleDialog" fullscreen>
        <v-card height="auto" class="pa-0">
            <div style="height: 30vh; width: 100%" :style="selectedArticle.mediumImage ? {'background-image':`url(${selectedArticle.smallImage} !important`, 'background-size': 'cover'} : ''">
                <div style="height: 100%; width: 100%;background-image: linear-gradient(to bottom, #3d3d3d57, #3d3d3d); ">
                    <div style="position: absolute; width: 80%; top: 5vh; left: 3vw">
                        <h2 style="line-height:1.3" class="mb-2" v-if="selectedArticle.Title">{{ selectedArticle.Title }}</h2>
                        <span>
                            <v-icon>account_circle</v-icon>{{ selectedArticle.Author ?? '' }}
                        </span>
                        <br>
                        <span>
                            <v-icon>schedule</v-icon>{{ formatPublicationDate(selectedArticle.createdAt) }}
                        </span>

                    </div>
                </div>
            </div>
            <div class="px-7" style="margin-bottom: 5%;">
                <v-card-text v-if="selectedArticle.Body" style="white-space: pre-line" v-html="markdownToHtml(selectedArticle.Body)">
                </v-card-text>
                <div class="px-1">
                    <v-list>
                        <v-list-group v-if="selectedArticle.Citations" no-action style="padding-left: 0;">
                            <template v-slot:activator>
                                <v-list-item-content>
                                    <v-list-item-title class="headline">Sources</v-list-item-title>
                                </v-list-item-content>
                            </template>

                            <v-list-item-group style="padding-left: 0;">
                                <v-list-item v-for="(citation, index) in selectedArticle.Citations" :key="index">
                                    <v-list-item-content>
                                        <v-list-item-title style="font-size: 12px; white-space: normal !important;">{{ citation.Title }}.<br> {{ citation["Source URL"] }}: ({{ citation.Source }})</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list-item-group>
                        </v-list-group>

                        <v-alert v-else :value="true" type="info">No Sources available</v-alert>
                    </v-list>
                </div>
            </div>

            <v-card tile style="height: 5%; width: 100%; position: fixed; bottom: 0; background-color: var(--v-greyRaised-base) !important;">
                <v-row no-gutters style="height: 100%">
                    <v-col class="d-flex justify-center align-center">
                        <v-btn icon @click="articleDialog = false">
                            <v-icon>arrow_back_ios</v-icon>
                        </v-btn>
                    </v-col>
                    <v-col class="d-flex justify-center align-center">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn icon @click="likeArticle()" v-bind="attrs" v-on="on">
                                    <v-icon :key="updateLike" :color="selectedArticle.liked ? 'edit' : ''">thumb_up</v-icon>
                                </v-btn>
                            </template>
                            <span>Coming Soon</span>
                        </v-tooltip>
                    </v-col>

                    <v-col class="d-flex justify-center align-center">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn icon v-bind="attrs" v-on="on">
                                    <v-icon>share</v-icon>
                                </v-btn>
                            </template>
                            <span>Coming Soon</span>
                        </v-tooltip>
                    </v-col>
                </v-row>
            </v-card>
        </v-card>
    </v-dialog>
</div>
</template>

<script>
export default {
    data: () => ({
        articles: [],
        articleSearch: null,
        articlePage: 1,
        articleTotal: 100,
        dummy: false,
        articleParams: {
            limit: 15,
            offset: 0
        },
        mainArticleKey: 0,
        latestArticle: {},
        otherLatestArticles: [],
        selectedArticle: {},
        search: "",
        updateLike: 0,
        loadingArticleData: false,
        articleDialog: false,
        loading: false,
    }),
    async created() {
        await this.fetchArticles()
    },
    computed: {
        categorisedArticles() {
            let categorisedArticles = [];

            if (this.articles) {
                let categories = new Set(this.articles.map(x => x.mainTag));
                for (let category of categories) {
                    let obj = {
                        category: category,
                        articles: this.articles.filter(x => x.mainTag == category),
                        currentArticleIndex: 0
                    }
                    categorisedArticles.push(obj);
                }
            }

            return categorisedArticles;
        },
    },
    methods: {
        formatPublicationDate(dateString) {
            const options = {
                year: "numeric",
                month: "short",
                day: "numeric",
                hour: "numeric",
                minute: "numeric",
                hour12: true,
                timeZone: 'UTC', // Set the timeZone option to UTC
            };

            const formattedDate = new Date(dateString).toLocaleString("en-ZA", options);

            return formattedDate;
        },
        likeArticle() {
            this.articles.find(x => x.fileName == this.selectedArticle.fileName).liked = !this.selectedArticle.liked
            this.updateLike++
        },
        openDummy() {
            this.dummy = true
        },
        isFirstArticleInView(category) {
            return category.currentArticleIndex <= 0;
        },
        isLastArticleInView(category) {
            let totalSets = Math.ceil(category.articles.length / 4);
            return category.currentArticleIndex >= totalSets - 1;
        },
        markdownToHtml(markdown) {
            return markdown.split('\n').map(line => {
                if (line.startsWith('### ')) {
                    return `<h4>${line.slice(4)}</h4>`;
                } else if (line.startsWith('## ')) {
                    return `\n\n<h3>${line.slice(3)}</h3>`;
                } else if (line.startsWith('# ')) {
                    return ``;
                } else {
                    return line;
                }
            }).join('');
        },
        moveSlider(slider, direction, category) {
            let e = document.getElementById(slider);
            let sliderIndex = parseInt(getComputedStyle(e).getPropertyValue('--slider-index'));
            let categoryObj = this.categorisedArticles.find(x => x.category == category.category);
            if (direction == 'left') {
                e.style.setProperty('--slider-index', sliderIndex - 1);
                this.$set(categoryObj, 'currentArticleIndex', categoryObj.currentArticleIndex - 1);
            }
            if (direction == 'right') {
                e.style.setProperty('--slider-index', sliderIndex + 1);
                this.$set(categoryObj, 'currentArticleIndex', categoryObj.currentArticleIndex + 1);
            }
        },
        async fetchArticles() {
            this.loading = true;
            let data = await this.$API.fetchArticles();
            this.articles = data.articles;
            console.log(data)
            this.articleTotal = data.totalArticles;
            this.loading = false;

            // Sort articles based on 'createdAt' in descending order
            this.articles.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

            // Get the latest article and remove it from the array
            this.latestArticle = this.articles.shift();
            let articleData = await this.$API.readArticle({
                fileName: this.latestArticle.fileName
            })
            this.latestArticle = {
                ...this.latestArticle,
                ...articleData
            }
            this.mainArticleKey++
            // get the next 4 articles after the first one
            // this.otherLatestArticles = this.articles.splice(0, 4);
            console.log(this.latestArticle)
        },

        async readArticle(article) {
            this.loadingArticleData = true
            this.loading = true
            this.selectedArticle = {}
            let selectedArticle = await this.$API.readArticle({
                fileName: article.fileName
            })
            this.selectedArticle = {
                ...article,
                ...selectedArticle
            }
            console.log({
                article
            })
            this.selectedArticle = {
                ...this.selectedArticle,
                "Publication Date": article.createdAt
            };
            console.log({
                selectedArticle
            })
            this.loadingArticleData = false
            this.articleDialog = true
            this.loading = false
        }
    }
}
</script>

<style scoped>
.subheaderTitle {
    font-size: 18px;
    font-weight: 600;
}

.exploreAll {
    opacity: 0;
    text-transform: capitalize;
    font-size: 12px;
    font-weight: 400;
    transition: opacity 0.3s ease-in-out;
}

.subheaderTitle:hover .exploreAll {
    opacity: 1;
    cursor: pointer;
}

.carousel {
    width: 100%;
    display: flex;
    justify-content: center;
    overflow: hidden;
}

.slider {
    --items-per-screen: 4;
    --slider-index: 0;
    display: flex;
    flex-grow: 1;
    transform: translateX(calc(var(--slider-index) * -100%));
    transition: transform 0.5s ease-in-out;
}

.articleTitle {
    word-break: keep-all !important;
    font-size: 16px;
    font-weight: 600;
    color: white;
    line-height: 1.5;
    text-shadow: 0px 0px 20px rgba(0, 0, 0, 20);
}

.articleCard {
    --items-per-screen: 4;
    max-width: calc(100% / var(--items-per-screen));
    flex: 0 0 calc(100% / var(--items-per-screen));
    width: calc(100% / var(--items-per-screen));
    aspect-ratio: 16/9;
    padding: 0;
    margin: 0.25rem;
    background-size: cover;
    transform: scale(1);
    transition: transform 0.2s ease-in-out, opacity 0.3s ease-in-out;
    backface-visibility: hidden;
}

.articleCard_mobile {
    --items-per-screen: 1;
    max-width: calc(100% / var(--items-per-screen));
    flex: 0 0 calc(100% / var(--items-per-screen));
    width: calc(100% / var(--items-per-screen));
    aspect-ratio: 16/9;
    padding: 0;
    margin: 0.25rem;
    background-size: cover;
    transform: scale(1);
    transition: transform 0.2s ease-in-out, opacity 0.3s ease-in-out;
    backface-visibility: hidden;
}

.articleCard:hover {
    transform: scale(1.04);
    opacity: 1;
    z-index: 11;

}

.textWrapper {
    height: 100%;
    width: 100%;
    /* add a linear gradient that gets darker towards the bottom. must be in black */
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 1));
}

.textBottom {
    contain: content;
    position: absolute;
    bottom: 0;
    height: 40%;
    width: 100%;
    transition: height 0.3s ease-in-out;
}

.textBottom_mobile {
    contain: content;
    position: absolute;
    bottom: 0;
    height: 100%;
    width: 100%;
}

.textWrapper:hover .textBottom {
    height: 100%;
}

.showOnHover {
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
}

.textWrapper:hover .showOnHover {
    opacity: 1;
}

.handle {
    height: inherit !important;
    padding: 0;
    flex-grow: 0;
    width: 32px;
    opacity: 0.4;
    min-width: 32px !important;
    z-index: 10;
    margin: 0.25rem 0;
    background-color: var(--v-component-base) !important;
}

.left-handle {
    font-size: 40px;
    opacity: 0;
}

.right-handle {
    font-size: 50px;
    opacity: 0;
}

.handle:hover,
.handle:focus {
    opacity: 1;
    cursor: pointer;
}

.handle:hover .left-handle,
.handle:focus .left-handle {
    opacity: 1;
    transform: scale(1.1);
}

.handle:hover .right-handle,
.handle:focus .right-handle {
    opacity: 1;
    transform: scale(1.1);
}

.carousel:hover .handle {
    opacity: 0.8;
}

.carousel:hover .left-handle {
    opacity: 0.8;
}

.carousel:hover .right-handle {
    opacity: 0.8;
}

.tempView {
    height: 60vh;
    width: 100vw;
}

.tempView_mobile {
    height: 65vh;
    width: 100vw;
}

.tempView2 {
    background-image: linear-gradient(to bottom, #212121 25%, 50%, #000000) !important;
    height: auto;
    width: 97vw;
    padding-bottom: 4vh
}

.tempView2_mobile {
    background-image: linear-gradient(to bottom, #212121 25%, 50%, #141414) !important;
    height: auto;
    width: 100vw;
    padding-bottom: 4vh
}

.mainArticle::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 68vh;
    width: 100%;
    background-size: cover;
    opacity: 0.3;
    transform: scale(1);
    transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.mainArticle {
    position: relative;
    opacity: 1;
}

.mainArticle:hover::before {
    opacity: 0.5;
    transform: scale(1.01);
}

.articleContent {
    opacity: 1;
    z-index: 5;
    position: absolute;
}

.smallArticle {
    background-color: var(--v-greyBase-base) !important;
    height: calc((68vh - 0.8vh) * 0.5);
    width: 100%;
    transform: scale(1);
    transition: transform 0.3s ease-in-out;
    ;
}

.smallArticle:hover {
    background-color: var(--v-greyBase-lighten1) !important;
    transform: scale(1.01);
    box-shadow: 100px
}

.otherArticles {
    height: 25vh;
    width: 100%;

}

@media (max-width: 100px) {
    .articleCard {
        --items-per-screen: 3;
    }

    .slider {
        --items-per-screen: 3;
    }
}
</style>
